.app-bar .page-title-placeholder {
  background-image: url("../../images/fruiticalogo.png");
  background-position: left;
  background-size: auto 140%;
  background-repeat: no-repeat;
  width: 200px;
  height: 64px;
}

p,
ul,
li {
  color: rgba(0, 0, 0, 0.87);
}

.app-toolbar {
  min-height: 82px !important;
}

.drawer-header {
  color: #008823;
  font-size: 18px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.sub-list {
  background: #f5f5f5;
}

.sub-sub-list {
  background: #eeeeee;
}

.sub-item {
  padding-left: 26px !important;
}

.sub-sub-item {
  padding-left: 32px !important;
}

.hidden {
  display: none;
}

.menu-list svg {
  color: rgba(0, 0, 0, 0.57);
}

.more-icon {
  transition: 0.2s !important;
}

.rotate {
  transform: rotate(180deg);
}

.menu-item-text>span {
  font-size: 15px !important;
}

.component-header-img {
  width: 100%;
  height: 100%;
}

.card-image {
  text-align: center;
}

.card-image img {
  max-width: 620px;
}

.component-text-container table {
  width: 100%;
  margin-bottom: 1em;
}

.component-text-container table td:nth-child(2) {
  text-align: right;
}

.tile-icon-bar {
  display: none;
}

.Competition1 img {
  margin-top: 12px;
}

.Component a {
  text-decoration: none;
  color: #008823;
}

.Component a:hover {
  color: #047721;
}

ul {
  list-style: none;
}

.Mokate ul li::before,
.Mprofood ul li::before,
.Mission ul li::before {
  content: "\2022";
  color: #008823;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.wild-card-image-container {
  background: url("../../images//juice.jpg");
}

.mprofood-card-image-container {
  background: url("../../images/meat.jpeg");
  background-position: 50% 60%;
}

.supperfood-card-image-container {
  background: url("../../images/peanut.jpg");
  background-position: bottom;
}

.card-image-container {
  width: 100%;
  height: 200px;
  background-size: cover;
}

h4,
b {
  font-weight: 500 !important;
}

.exp-panel>div:last-of-type {

  position: relative;
}

.background-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  transform: scaleX(-1);
  z-index: 0;
  background-position: 0% 100%;
}

.mokacb-img {
  background-image: url('../../images/mokacb.jpg');
}

.mokaero-img {
  background-image: url('../../images/mokaero.jpg');
}

.mokasw-img {
  background-image: url('../../images/mokasw.jpg');
}

.mokatetogo-img {
  background-image: url('../../images/mokatetogo.jpg');
}

.mokacw-img {
  background-image: url('../../images/mokacw.jpg');
}

.mokafp-img {
  background-image: url('../../images/mokafp.jpg');
}

.mokamm-img {
  background-image: url('../../images/mokamm.jpg');
}

.text {
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .app-content {
    padding: 24px 14px 14px 14px !important;
  }
}